import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import UserMenu from './components/UserMenu';
import MailingLists from './components/MailingLists';
import { useUser } from './providers/UserProvider';

const App = () => {
  const { user } = useUser();
  if (!user) {
    return null;
  }
  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Communication des Syndicats
          </Typography>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Container component="main" sx={{ padding: 2 }}>
        <MailingLists />
      </Container>
    </>
  );
};

export default App;
