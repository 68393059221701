import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BDCD00',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: '#639f65',
          backgroundColor: '#fff',
        },
      },
    },
  },
});

export default theme;
