import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { Logout } from '@mui/icons-material';
import { User } from '../model/User';
import { useUser } from '../providers/UserProvider';
import { interactiveLogout } from '../config/azureAuth';

/**
 * Given a name this method returns the first letter of the name.
 * For composed names separated with - or space it returns the first letter of the first and the last name
 *
 * @param name
 * @return initials
 */
function computeInitials(name: string): string {
  if (name === '') {
    return '';
  }
  const formattedName = formatName(name);
  if (formattedName.includes(' ')) {
    const splitName = formattedName.split(' ');
    const lastNamePosition = splitName.length - 1;
    return splitName[0][0] + splitName[lastNamePosition][0];
  }
  if (formattedName.includes('-')) {
    const splitName = formattedName.split('-');
    const lastNamePosition = splitName.length - 1;
    return splitName[0][0] + splitName[lastNamePosition][0];
  }
  const initial = formattedName.at(0) ?? '';
  return initial.toUpperCase();
}

/**
 * Remove any strange character and remove - and spaces at the beginning and at the end
 * of the string while it doesn't start and end with a valid letter
 *
 * @param name
 * @return formattedName
 */
export function formatName(name: string) {
  let formattedName = name.trim().replace(/[^a-zA-Z0-9- ÂÊÎÔÛâêûîôÄËÏÖÜäëüïöÀàÆæÇçÉéÈèŒœÙù]/g, '');
  while (formattedName.startsWith('-')) {
    formattedName = formattedName.substring(1).trim();
  }
  while (formattedName.endsWith('-')) {
    formattedName = formattedName.slice(0, -1).trim();
  }
  return formattedName;
}

/**
 * method calling computeInitials twice (for firstName and lastName)
 * and concatenate results to return complete initials
 * result can't contain more than 4 letters.
 *
 * @param firstName
 * @param lastName
 * @return initials
 */
export function computeAndConcatenateInitials(firstName: string, lastName: string) {
  return computeInitials(firstName).concat(computeInitials(lastName));
}

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleButtonClick = (e: MouseEvent) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user: User | undefined = useUser().user;

  if (user) {
    const initials = computeAndConcatenateInitials(user.firstName ?? '', user.lastName ?? '');

    return (
      <>
        <IconButton onClick={handleButtonClick}>
          <Avatar>{initials}</Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Avatar sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
            {user.firstName} {user.lastName}
          </MenuItem>
          <Divider />
          <MenuItem onClick={interactiveLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            Déconnexion
          </MenuItem>
        </Menu>
      </>
    );
  } else {
    return null;
  }
};
export default UserMenu;
