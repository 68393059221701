import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

// Initialize MSAL
import msalPromise from './config/azureAuth';
import { UserProvider } from './providers/UserProvider';
import { SnackbarProvider } from 'notistack';

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);

/*
 * Checking that the user is logged in before initiating the React
 * application. If the user is not logged in, a redirection to the
 * Azure AD is triggered.
 */
msalPromise.then(() => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={4000}
          >
            <App />
          </SnackbarProvider>
        </UserProvider>
      </ThemeProvider>
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
