import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getAuthenticatedUser } from '../config/azureAuth';
import { AuthenticatedUser } from '../model/AuthenticatedUser';
import { User } from '../model/User';

type UserContextType = {
  user?: User;
  fetch: typeof fetch;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>();

  useEffect(() => {
    const fetchUser = async () => {
      const authUser = await getAuthenticatedUser();
      setAuthenticatedUser(authUser);
    };
    fetchUser().then();
  }, []);
  // We don't want to listen on account changes as it is only there to check if we can keep its info.

  const authenticatedFetch: typeof fetch = useCallback(
    async (input, init) => {
      if (!authenticatedUser) {
        //TODO improve here with Try catch ?
        throw new Error('échec de connexion');
      }
      const fetchInit: RequestInit = {
        ...init,
      };
      const headers = new Headers(fetchInit.headers);
      headers.append('Authorization', `Bearer ${authenticatedUser.jwt}`);
      fetchInit.headers = headers;

      return await fetch(input, fetchInit);
    },
    [authenticatedUser],
  );

  return (
    <UserContext.Provider
      value={{
        user: authenticatedUser?.user,
        fetch: authenticatedFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => {
  const userInfo = useContext(UserContext);
  if (userInfo === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return userInfo;
};
