import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  Configuration,
} from '@azure/msal-browser';
import { AuthenticatedUser } from '../model/AuthenticatedUser';
import { User } from '../model/User';

type EnvConfig = {
  auth: {
    clientId: string;
    authority: string;
    redirectUri: string;
  };
  scopes: string[];
};

const envConfigs: { [hostname: string]: EnvConfig } = {
  localhost: {
    auth: {
      clientId: '35840657-ce0a-496f-81b6-180dc1d3913f',
      authority: 'https://login.microsoftonline.com/03558f28-9a55-41a6-bf71-2097159b803d',
      redirectUri: 'http://localhost:3000/',
    },
    scopes: ['api://comsyn-dev-api/access'],
  },
  'comsynint.captrain.fr': {
    auth: {
      clientId: 'fbd73414-8efd-4ba0-976c-aa813c95ba3c',
      authority: 'https://login.microsoftonline.com/03558f28-9a55-41a6-bf71-2097159b803d',
      redirectUri: 'https://comsynint.captrain.fr/',
    },
    scopes: ['api://comsyn-int-api/access'],
  },
  'comsynre7.captrain.fr': {
    auth: {
      clientId: '60abb299-ca95-4148-bb60-af76928ed034',
      authority: 'https://login.microsoftonline.com/8c1e59a6-3708-4b42-8f3e-594ef1821de6',
      redirectUri: 'https://comsynre7.captrain.fr/',
    },
    scopes: ['api://comsyn-re7-api/access'],
  },
  'comsyn.captrain.fr': {
    auth: {
      clientId: '426b5c31-3071-4f94-8a09-7dd3ef45cece',
      authority: 'https://login.microsoftonline.com/8c1e59a6-3708-4b42-8f3e-594ef1821de6',
      redirectUri: 'https://comsyn.captrain.fr/',
    },
    scopes: ['api://comsyn-prod-api/access'],
  },
};

const envConfig = envConfigs[window.location.hostname] ?? envConfigs.localhost!;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig: Configuration = {
  auth: envConfig.auth,
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.info(message);
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: envConfig.scopes,
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized
 * on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Trigger an interactive login by redirecting the user's browser. This will navigate away from
 * the page, so any code that follows this function will not execute.
 * @returns {Promise<void>}
 */
const interactiveLogin = async () => {
  try {
    await msalInstance.acquireTokenRedirect(loginRequest);
  } catch (e) {
    console.error('Error in login', e);
    throw e;
  }
};

/**
 * Trigger an interactive logout by redirecting the user's browser. This will navigate away from
 * the page, so any code that follows this function will not execute.
 * @returns {Promise<void>}
 */
export const interactiveLogout = () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    return msalInstance.logoutRedirect();
  }
  return msalInstance.logoutRedirect({ account: accounts[0] });
};

/**
 * Get AuthenticationResult from Azure if logged in. Otherwise trigger a login.
 */
const getAuthenticationResult = async (): Promise<AuthenticationResult | null> => {
  const accounts = msalInstance.getAllAccounts();
  try {
    if (accounts.length === 0) {
      await interactiveLogin();
      return null;
    }
    return await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      await interactiveLogin();
      return null;
    }
    throw error;
  }
};

type Claims = {
  email: string;
  family_name: string;
  given_name: string;
};

/**
 * Get user and an access token from AuthenticationResult.
 */
export const getAuthenticatedUser = async (): Promise<AuthenticatedUser | undefined> => {
  try {
    const authenticationResult = await getAuthenticationResult();
    if (authenticationResult) {
      const claims: Claims = authenticationResult.idTokenClaims as Claims;

      const user: User = {
        firstName: claims.given_name,
        lastName: claims.family_name,
        email: claims.email,
      };
      return {
        jwt: authenticationResult.accessToken,
        user: user,
      };
    }
    return undefined;
  } catch (error) {
    throw error;
  }
};

export default msalInstance.handleRedirectPromise().catch((error) => {
  console.error('handleRedirectPromise error', error);
});
