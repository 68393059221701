import { Subscription } from '../model/Subscription';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

type MailingListItemProps = {
  subscription: Subscription;
  updateSubscriptionInList: (subscription: Subscription) => void;
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
};

function computeSnackbarSuccessMessage(
  subscription: Subscription,
  nextSubscribed: boolean,
): string {
  if (nextSubscribed) {
    return 'Vous êtes abonné à la liste de diffusion ' + subscription.mailingListLabel;
  } else return 'Vous êtes désabonné de la liste de diffusion ' + subscription.mailingListLabel;
}

function updateSubscriptionStatusInMailingList(
  updateSubscriptionsList: (subscription: Subscription) => void,
  subscription: Subscription,
  nextSubscribed: boolean,
) {
  updateSubscriptionsList({
    ...subscription,
    subscribed: nextSubscribed,
  });
}

const MailingListItem = ({
  subscription,
  updateSubscriptionInList,
  fetch,
}: MailingListItemProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const updateSubscription = async (nextSubscribed: boolean) => {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'text/plain' },
      body: String(nextSubscribed),
    };

    const backEndResponse = await fetch(
      '/api/mailing-lists/' + subscription.mailingListId,
      requestOptions,
    );
    if (backEndResponse.status === 200) {
      enqueueSnackbar(computeSnackbarSuccessMessage(subscription, nextSubscribed), {
        variant: 'success',
      });
    } else {
      updateSubscriptionStatusInMailingList(
        updateSubscriptionInList,
        { ...subscription, subscribed: !nextSubscribed },
        nextSubscribed,
      );
      enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
    }
  };

  const onCheckBoxClick = () => {
    const nextSubscribed: boolean = !subscription.subscribed;
    updateSubscriptionStatusInMailingList(updateSubscriptionInList, subscription, nextSubscribed);
    // noinspection JSIgnoredPromiseFromCall
    updateSubscription(nextSubscribed);
  };

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          '& > :not(style)': {
            m: 1,
            width: 120,
            height: 24,
            justifyContent: 'space-between',
          },
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={subscription.subscribed} onClick={onCheckBoxClick} />}
          label={subscription.mailingListLabel}
          labelPlacement="start"
        />
      </Paper>
    </>
  );
};

export default MailingListItem;
