import { useUser } from '../providers/UserProvider';
import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Subscription } from '../model/Subscription';
import MailingListItem from './MailingListItem';
import ErrorDialog from './ErrorDialog';

const MailingLists = () => {
  const { user, fetch } = useUser();

  useEffect(() => {
    if (user) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await fetch('/api/mailing-lists');
          const initialSubscriptions = (await response.json()) as Subscription[];
          setSubscriptions(
            initialSubscriptions.sort((a, b) =>
              a.mailingListLabel.localeCompare(b.mailingListLabel, 'fr', { sensitivity: 'base' }),
            ),
          );
          setLoading(false);
        } catch {
          setLoading(false);
          setLoadingError(true);
        }
      };
      fetchData().then();
    }
  }, [fetch, user]);

  const updateSubscription = (subscription: Subscription) => {
    const newSubscriptions = subscriptions.map((subscriptionInArray) => {
      if (subscriptionInArray.mailingListId === subscription.mailingListId) {
        return subscription;
      }
      return subscriptionInArray;
    });
    setSubscriptions(newSubscriptions);
  };

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  if (!user) {
    return null;
  }
  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {user.firstName} {user.lastName}
      </Typography>
      <Divider
        variant="middle"
        sx={{
          my: 3,
        }}
      />
      <Typography
        sx={{
          textAlign: 'justify',
        }}
      >
        Bienvenue sur la page de gestion de vos abonnements aux listes de diffusion des
        organisations syndicales de CAPTRAIN France. En cas d’acceptation de votre part, vous
        recevrez leurs communications sur votre adresse mail professionnelle :
      </Typography>
      <Typography
        sx={{
          my: 3,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {user.email}
      </Typography>
      <Typography
        sx={{
          my: 3,
          textAlign: 'justify',
          fontStyle: 'italic',
        }}
      >
        Vous êtes informé(e) que l’adhésion à une ou plusieurs de ces listes de diffusion est un
        droit, non une obligation, et que votre choix demeurera confidentiel.
      </Typography>
      <Typography
        sx={{
          my: 3,
          textAlign: 'justify',
        }}
      >
        Merci de procéder à votre choix ci-après.
      </Typography>
      <Typography
        sx={{
          my: 3,
          textAlign: 'justify',
          fontWeight: 'bold',
        }}
      >
        Je souhaite m’inscrire à la liste de diffusion de :
      </Typography>
      {loading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {loadingError && (
        <ErrorDialog
          title="Erreur de Chargement"
          message="Une erreur est survenue lors du chargement de vos abonnements aux listes de diffusion. Veuillez réessayer ultérieurement"
        />
      )}
      <Stack alignItems={'center'} spacing={3}>
        {subscriptions.map((subscription) => (
          <MailingListItem
            key={subscription.mailingListId}
            subscription={subscription}
            updateSubscriptionInList={updateSubscription}
            fetch={fetch}
          />
        ))}
      </Stack>
      <Typography
        sx={{
          my: 3,
        }}
      >
        A tout moment,{' '}
        <strong>
          vous avez la possibilité de vous désabonner d’une liste de diffusion, soit en cliquant sur
          le lien prévu à cet effet dans les mails que vous recevrez soit en décochant votre
          inscription ci-dessus.
        </strong>
      </Typography>
    </>
  );
};
export default MailingLists;
